import React from "react"
import Base from "../Base"

const error404 = () => (
  <Base title="404">
    <h4>404 - We couldn&apos;t find that page</h4>
  </Base>
)

export default error404
